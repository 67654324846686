import React from 'react'

import SatinFillIcon from '../img/SVG/statin-fill.svg';
import PatternFillIcon from '../img/SVG/pattern-fill.svg';
import MotiveFillIcon from '../img/SVG/motive-fill.svg';

import Feature from '../components/feature';


export const FeaturesFillsDe = () => (

    <div className="text-center">
        <div className="row">
            <div className="col-12 md:col-4 mt-12 ">
                <Feature title={"Plattstich Füllung"} icon={<SatinFillIcon/>}>
                    Plattstich zum Füllen von Formen.
                </Feature>
            </div>

            <div className="col-12 md:col-4 mt-12 ">
                <Feature title={"Motiv Füllung"} icon={<MotiveFillIcon/>}>
                    Formen mit aufwändigen Motiven gefüllt.
                </Feature>
            </div>

            <div className="col-12 md:col-4 mt-12 ">
                <Feature title={"Muster-Füllstich"} icon={<PatternFillIcon/>}>
                    Deckender Füllstich mit Mustern wir Herzen, Diamanten und mehr.
                </Feature>
            </div>
        </div>

    </div>

);

export default FeaturesFillsDe
