import React from 'react'

import SimpleRunIcon from '../img/SVG/simple-run.svg';
import SatinRunIcon from '../img/SVG/satin-run.svg';
import MotiveRunIcon from '../img/SVG/motive-run.svg';

import Feature from '../components/feature';

export const FeaturesOutlinesDe = () => (
    <div className="text-center">
        <div className="row">
            <div className="col-12 md:col-4 mt-12 ">
                <Feature title={"Stepplinie"} icon={<SimpleRunIcon/>}>
                    Ein einfacher Stich entlang einer Form.
                </Feature>
            </div>

            <div className="col-12 md:col-4 mt-12 ">
                <Feature title={"Plattstich"} icon={<SatinRunIcon/>}>
                    Plattstich entlang einer Form.
                </Feature>
            </div>

            <div className="col-12 md:col-4 mt-12 ">
                <Feature title={"Motivstich"} icon={<MotiveRunIcon/>}>
                    Aufwändigeres Motiv entlang einer Form.
                </Feature>
            </div>
        </div>
    </div>
);

export default FeaturesOutlinesDe
