import React from 'react'


import SymbolLibraryImage from '../img/SVG/symbol-library.svg';
import YourOwnDesignImage from '../img/SVG/own-design.svg';
import FontImage from '../img/SVG/fonts.svg';

import Row from '../components/row.js';


class ImageBlock extends React.Component {

    render() {
        return (
        <Row className={'flex flex-wrap mt-8 mb-8 text-lg items-center'}>
            <div className="w-full lg:pr-2 mx-auto lg:w-1/2 sm:px-10 md:px-6 lg:px-12 md:max-w-sm">
                {this.props.image}
            </div>
            <div className="flex flex-col mx-auto justify-center xl:pl-2 xl:pr-0 lg:w-1/2 lg:px-12">
                <h4 className={'text-normal mb-4 font-bold'}>{this.props.title}</h4>
                {this.props.children}
            </div>
        </Row>
        );
    }
};

class ImageBlockP extends React.Component {

    render() {
        return (
            <p className={'pb-4 leading-normal text-blue-100'}>
                    {this.props.children}
            </p>
        );
    }
};

export const FeaturesShapesDe = () => (

    <>

        <div className="section-header">
            <h2>Erstelle deine eigenen Designs</h2>
            <p>Mit 2stitch ist es einfach, deine eigenen Designs umzusetzen.</p>
        </div>


        <ImageBlock image={<YourOwnDesignImage/>} title={'Entwirf dein eigenes Design'}>
            <ImageBlockP>
                Mit den mächtigen Werkzeugen kannst Du eigene Designs aus Linien und gefüllten
                Formen erstellen. Wähle aus
                verschiedenen Füllungen und Umrandungen.
            </ImageBlockP>
            <ImageBlockP>
                Du kannst Elemente in einer Bibliothek speichern, um sie
                später in anderen Designs zu verwenden.
            </ImageBlockP>
        </ImageBlock>

        <ImageBlock image={<SymbolLibraryImage/>} title={'Verwende Designs aus der mitgelieferten Bibliothek'}>
            <ImageBlockP>2stitch kommt mit einer umfangreichen Stickmuster-Bibliothek, die Du verwenden kannst
                um eigene Designs zu entwerfen oder vorhandene zu verändern.
            </ImageBlockP>
            <ImageBlockP>Die Formen kannst Du mit verschiedenen Füllungen und Umrandungen anpassen.</ImageBlockP>
        </ImageBlock>

        <ImageBlock image={<FontImage/>} title={'Füge Texte und Monogramme hinzu'}>
            <ImageBlockP>
                Verwende die Zeichensätze auf deinem Computer zum erstellen von
                Texten und Monogrammen.
                Auch hier kannst Du wider aus verschiedenen Füllungen und Umrandungen wählen.
            </ImageBlockP>
            <ImageBlockP>
                Buchsteben können verzerrt und verbogen werden. Texte können entlang von Linien oder
                Formen laufen.
            </ImageBlockP>
        </ImageBlock>
    </>
);

/*

    <div className="container features text-center pt-4 pb-4 mt-4 mb-4">

        <h2>Shapes</h2>
        <p>
            Shapes are the building blocks of your designs.
            You can set outline and fill stitches.
        </p>

        <Row>

            <Feature title="Line / Polygon" icon={<PolyLineIcon/>}>
                Use this tool to create a straight line / polygon.
            </Feature>

            <Feature title="Curve" icon={<CurveLineIcon/>}>
                A curve is similar to a polygon but with rounded edges.
            </Feature>

            <Feature title="Filled Polygon" icon={<PolyFillIcon/>}>
                A shape with straight lines that consists of a fill and an outline.
            </Feature>

            <Feature title="Filled Curve" icon={<CurveFillIcon/>}>
                A curved shape with fill and outline.
            </Feature>

            <Feature title="Text" icon={<TextIcon/>}>
                The text tool converts any font on your computer
                into a shape.
            </Feature>

            <Feature title="Shape Library" icon={<ShapeLibraryIcon/>}>
                2stitch comes with many pre-defined shapes that you can use
                in your designs.
            </Feature>

        </Row>

    </div>

 */

export default FeaturesShapesDe
