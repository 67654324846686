import React from 'react'
import Layout from '../../components/layout';
import {graphql} from 'gatsby'
import Img from "gatsby-image";
import FeaturesShapesDe from "../../blocks/features-shapes-de";
import FeaturesOutlinesDe from "../../blocks/features-outlines-de";
import FeaturesFillsDe from "../../blocks/features-fills-de";
import DownloadButton from "../../components/download-button";
import HeroBackground from "../../components/hero-background";


export default class IndexPage extends React.Component {

    render()
    {
        return (

            <Layout lang={'de'}
                    title={'2stitch Studio - Professionelle Stick-Software zum kleinen Preis'}
                    description={'Erschwingliche Sticksoftware für Windows und MacOS. Unterstützt alle gängigen Maschinen von Bernina, Brother, Husqvarna, Pfaff, Janome'}
                    translated={{en:'/studio/'}}
                    location={this.props.location.pathname}
            >

                <div className={'leading-normal text-lg'}>
                    <HeroBackground img={this.props.data.heroImage.childImageSharp.fluid}>
                        <div className="container mx-auto text-center">
                            <h1 className="text-xl md:text-5xl text-blue-50 font-medium mb-3 ">2stitch Studio</h1>
                            <p className="text-md md:text-3xl text-blue-50">Professionelle Stick-Software zum kleinen Preis</p>

                            <DownloadButton linkMac={'/download/'} linkWindows={'/download/'}/>

                        </div>
                    </HeroBackground>


                    <div className="bg-blue-500 text-blue-50 pt-12 pb-8">
                        <div className={"container mx-auto"}>
                            <FeaturesShapesDe/>
                        </div>
                    </div>

                    <div className={''}>


                        <div className="container mx-auto pt-12 pb-4 ">

                            <div className="section-header">
                                <h2>Wie sieht das ganze aus?</h2>
                                <p>
                                    Unser Ziel ist es, Dir ein Programm zur Verfügung zu stellen,
                                    das einfach in der Bedienung aber mächtig in den Funktionen ist.
                                </p>
                            </div>

                            <div className="container mx-auto mx-auto">

                                <Img fluid={this.props.data.screenShotImage.childImageSharp.fluid}/>

                            </div>
                        </div>
                    </div>

                    <div className="bg-blue-500 text-blue-50">
                        <div className="container mx-auto pt-12 pb-6 ">
                            <div className="section-header">
                                <h2>Stiche &amp; Füllungen</h2>
                                <p>
                                    Wähle aus den folgenden Stichen, um deine Designs zu erstellen.<br/>
                                </p>
                            </div>

                            <FeaturesOutlinesDe/>
                            <FeaturesFillsDe/>
                        </div>
                    </div>


                    <div className="bg-blue-50 text-blue-900">

                        <div className="container mx-auto pt-12 pb-12">

                            <div className="section-header">
                                <h2>Funktioniert mit deinem Computer und Deiner Stickmaschine</h2>
                                <p>
                                    Läuft auf Windows und macOS.<br/>
                                    Kein Emulator nötig. Sofort herunterladen.<br/>
                                    Fairer Preis. Kein Dongle.<br/>
                                </p>
                            </div>

                            <div className="mt-12">
                                <h3>Unterstützte Stickmaschinen Marken</h3>
                            </div>

                            <p>
                                Mit 2stitch Studio kannst Du Stickdateien in den folgenden
                                Formaten erstellen:
                            </p>

                            <div className="row mt-4">
                                <div className={'col-12 lg:col-4'}>
                                    <table className="table-vertical">
                                        <tbody>
                                        <tr>
                                            <td>Poem, Huskygram, Singer</td>
                                            <td className={'w-1/6 xl:w-auto'}>CSD</td>
                                        </tr>
                                        <tr>
                                            <td>Tajima</td>
                                            <td>DST</td>
                                        </tr>
                                        <tr>
                                            <td>ZSK</td>
                                            <td>DSZ</td>
                                        </tr>
                                        <tr>
                                            <td>Melco</td>
                                            <td>EXP</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={'col-12 lg:col-4'}>
                                    <table className="table-vertical">
                                        <tbody>

                                        <tr>
                                            <td>Husqvarna/Viking</td>
                                            <td className={'w-1/6 xl:w-auto'}>HUS</td>
                                        </tr>
                                        <tr>
                                            <td>Janome/Elna/Kenmore</td>
                                            <td>JEF</td>
                                        </tr>
                                        <tr>
                                            <td>Pfaff</td>
                                            <td>PCS</td>
                                        </tr>
                                        <tr>
                                            <td>Babylock, Brother, Deco</td>
                                            <td>PEC, PES</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={'col-12 lg:col-4'}>
                                    <table className="table-vertical">
                                        <tbody>
                                        <tr>
                                            <td>Janome, Elna, Kenmore</td>
                                            <td className={'w-1/6 xl:w-auto'}>SEW</td>
                                        </tr>
                                        <tr>
                                            <td>Happy</td>
                                            <td>TAP</td>
                                        </tr>
                                        <tr>
                                            <td>Husqvarna Viking, Pfaff</td>
                                            <td>VIP</td>
                                        </tr>
                                        <tr>
                                            <td>Compucon, Singer</td>
                                            <td>XXX</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="bg-blue-500 pt-12 pb-12 text-blue-50">
                    <div className="container mx-auto">

                        <div className="section-header">
                            <h2>Noch mehr Möglichkeiten....</h2>
                        </div>

                        <div className={'row flex-wrap leading-normal text-lg'}>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Automatische Unterlegstiche</h4>
                                <p className=''>
                                    Füllungen und Plattstiche benötigen ein Gerüst aus Unterlegstichen, um
                                    stabil zu bleiben. 2stitch Studio berechnet diese automatisch für Dich.
                                </p>
                            </div>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Verzugsausgleich</h4>
                                <p>
                                    Stoffe ziehen sich beim Sticken zusammen und verformen
                                    Kreise zu Ovalen. Der Verzugsausgleich sorgt dafür, dass
                                    Kreise Kreise bleiben.
                                </p>
                            </div>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Garnkoffer</h4>
                                <p>
                                    2stitch Studio enthält eine Tabelle der Garne der verschieden Hersteller.
                                    Stelle eine Sammlung deiner eigenen Garne zusammen um sie schnell
                                    für deine Designs auswählen zu können.
                                </p>
                            </div>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Stick-Simulation</h4>
                                <p>
                                    Der Stick-Simulator zeigt Dir, wir die Datei gestickt wird
                                    bevor Du sie auf die Stickmaschine überträgst.
                                </p>

                            </div>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Realistische Vorschau</h4>
                                <p>
                                    Die realistische Vorschau zeigt Dir, wie die Stick-Datei aussehen wird,
                                    wenn sie gestickt ist.
                                </p>
                            </div>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Weitere Stickdatei-Formate </h4>
                                <p>
                                    Folgende Formate können importiert werden:<br/>

                                    .ART, .CND,
                                    .CSD, .DST, .DSZ, .EMB, .EMD,
                                    .EXP, .HUS, .JAN, .JEF,
                                    .PCS, .PCM, .PES, .PHB,
                                    .PHC, .PHD, .SEW, .SHV,
                                    .TAP, .VIP, .VP3, .XXX
                                </p>
                            </div>
                        </div>

                    </div>

                </div>


                <Img fluid={this.props.data.footerImage.childImageSharp.fluid}/>

            </Layout>
        )
            ;
    }
}


export const pageQuery = graphql`
query {

    heroImage: file(relativePath: { eq: "parallax-2.jpg" }) {
      ...fluidImage
    },

    screenShotImage: file(relativePath: { eq: "screenshot.jpg" }) {
      ...fluidImage
    },

    footerImage: file(relativePath: { eq: "threads.jpg" }) {
      ...fluidImage
    }
  }
`;
